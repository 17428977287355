@tailwind base;
@tailwind components;
@tailwind utilities;

.FuturaBook {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.FuturaBookET {
  font-family: "futura-pt", sans-serif;
  font-weight: 800;
  font-style: normal;
}
